// Material Design for Bootstrap
// ---------------------------------------------------

/* Sticky footer styles
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
	font-size: 14px;
}

@media (width >= 768px) {
	html {
	  font-size: 16px;
	}
}
  
body {
	margin-bottom: 60px; /* Margin bottom by footer height */
}

@media (width >= 1400px) {
  main,
  header,
  #main-navbar {
    padding-left: 240px;
  }
}

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0 0 0 / 10%);
  }